<template>
  <b-card no-body class="mb-2">
    <b-card-header class="align-items-center">
      <h4 class="mr-auto mb-0">
        <unicon name="bookmark" width="18" height="18" class="mr-1"></unicon>
        تفاصيل الموظف
      </h4>
    </b-card-header>

    <ValidationObserver ref="employeeDto">
      <b-form>
        <b-card-body>
          <b-card-text>
            <b-row>
              <b-col cols="12" md="6">
                <EKInputText
                  :rules="[
                    {
                      type: 'required',
                      message: 'اسم الموظف مطلوب',
                    },
                  ]"
                  label="اسم الموظف"
                  placeholder="ادخل اسم الموظف"
                  name="name"
                  v-model="employeeDto.name"
                />
                <EKInputSelect
                  label="منصب الموظف"
                  placeholder="اختر منصب الموظف"
                  :options="positionsList"
                  name="positionId"
                  :rules="[
                    {
                      type: 'required',
                      message: 'يرجى اختيار منصب الموظف',
                    },
                  ]"
                  v-model="employeeDto.positionId"
                />
              </b-col>
              <b-col cols="12" md="6">
                <EKInputImage
                  label="صورة الموظف"
                  required
                  title="أرفق الملف هنا أو اضغط لتحميل"
                  :value="
                    employeeDto.imageDto
                      ? employeeDto.imageDto.path
                        ? employeeDto.imageDto.path.replace(
                            /^/,
                            $store.getters['app/domainHost'] + '/'
                          )
                        : ''
                      : ''
                  "
                  @input="employeeDto.imageDto.file = $event"
                ></EKInputImage>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
        <b-card-footer class="py-1 d-flex">
          <b-button class="mr-1" @click="submitUpdateEmployee()" variant="primary">
            تعديل
          </b-button>
          <b-button variant="outline-primary" to="./">تراجع</b-button>
          <b-button
            variant="outline-danger"
            class="ml-auto"
            @click="deleteEmployee(employeeDto.id)"
            >حذف</b-button
          >
        </b-card-footer>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import { mapActions, mapState } from "vuex";
import { nullGuid } from "@/EK-core/util/global";
import { handleDashLangChange } from '@/libs/event-bus';
export default {
  components: {
    ValidationObserver,
    EKInputImage,
    EKInputText,
    EKInputSelect
  },
  props: {
    id: String,
  },
  data: () => ({
    nullGuid,
  }),
  computed: {
    ...mapState({
      employeeDto: (state) => state.employee.employeeDto,
      positionsList: (state) => state.employee.positionsList,
    }),
  },
  created() {
    this.getEmployeeDetails(this.id);
    handleDashLangChange(() => {
        this.getEmployeeDetails(this.id);
    });
  },
  methods: {
    ...mapActions(["getEmployeeDetails", "updateEmployee", "deleteEmployee"]),
    submitUpdateEmployee() {
      this.$refs.employeeDto.validate().then((success) => {
        if (
          success &&
          (this.employeeDto.imageDto.file || this.employeeDto.imageDto.path)
        ) {
          this.updateEmployee(this.employeeDto);
        }
      });
    },
    addNewImage(img) {
      this.employeeDto.imageDto.file = {
        id: nullGuid,
        file: img,
        path: "",
      };
    },
  },
  beforeDestroy() {
    this.$store.commit("RESET_ACTIVITY_DTO");
    this.$refs.employeeDto.reset();
  },
};
</script>
