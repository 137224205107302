var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"align-items-center"},[_c('h4',{staticClass:"mr-auto mb-0"},[_c('unicon',{staticClass:"mr-1",attrs:{"name":"bookmark","width":"18","height":"18"}}),_vm._v(" تفاصيل الموظف ")],1)]),_c('ValidationObserver',{ref:"employeeDto"},[_c('b-form',[_c('b-card-body',[_c('b-card-text',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputText',{attrs:{"rules":[
                  {
                    type: 'required',
                    message: 'اسم الموظف مطلوب',
                  } ],"label":"اسم الموظف","placeholder":"ادخل اسم الموظف","name":"name"},model:{value:(_vm.employeeDto.name),callback:function ($$v) {_vm.$set(_vm.employeeDto, "name", $$v)},expression:"employeeDto.name"}}),_c('EKInputSelect',{attrs:{"label":"منصب الموظف","placeholder":"اختر منصب الموظف","options":_vm.positionsList,"name":"positionId","rules":[
                  {
                    type: 'required',
                    message: 'يرجى اختيار منصب الموظف',
                  } ]},model:{value:(_vm.employeeDto.positionId),callback:function ($$v) {_vm.$set(_vm.employeeDto, "positionId", $$v)},expression:"employeeDto.positionId"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('EKInputImage',{attrs:{"label":"صورة الموظف","required":"","title":"أرفق الملف هنا أو اضغط لتحميل","value":_vm.employeeDto.imageDto
                    ? _vm.employeeDto.imageDto.path
                      ? _vm.employeeDto.imageDto.path.replace(
                          /^/,
                          _vm.$store.getters['app/domainHost'] + '/'
                        )
                      : ''
                    : ''},on:{"input":function($event){_vm.employeeDto.imageDto.file = $event}}})],1)],1)],1)],1),_c('b-card-footer',{staticClass:"py-1 d-flex"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitUpdateEmployee()}}},[_vm._v(" تعديل ")]),_c('b-button',{attrs:{"variant":"outline-primary","to":"./"}},[_vm._v("تراجع")]),_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteEmployee(_vm.employeeDto.id)}}},[_vm._v("حذف")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }